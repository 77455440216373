<template>
    <div class="booking-dialog-summary">

        <div class="booking-dialog-summary-info">

            <span v-html="t('booking-summary.message', dataForMessage)" /> 
            <br><br>

        </div>

        <div class="_booking-dialog-summary-img">
            <div style="height: 100%">
                
            </div>
        </div>


    </div>

    <!-- <div class="spacer"></div> -->

    <div class="booking-dialog-btn-next-wrap">
        <button
            class="booking-dialog-btn booking-dialog-btn-next ok"
            @click="$emit('next', next)">
            OK
        </button>
    </div>
			
</template>

<script>
import { useI18n } from 'vue-i18n'
import moment from 'moment'

export default {
    name: 'booking-summary',

    props: [ 'data', 'next', 'prev', 'isValid', 'loading' ],

    emits: [ 'next' ],

    setup(props) {
        const { t, locale } = useI18n()

        const dataForMessage = { ...props.data, date: moment(props.data.date).format('DD/MM/YYYY') }

        return {
            t,
            locale,
            dataForMessage
        }
    }
}
</script>
