<template>
    <div class="booking-dialog-content-title"
        v-text="t('booking-payment.subtitle')"></div>

    <form class="flex booking-dialog-payment" @submit="() => false">
        <input 
            v-model="data.payment.number"
            v-maska="'#### #### #### #### ####'"
            class="n"
            :placeholder="t('booking-payment.form.cardNumber')"
            :disabled="loading.is">

        <input 
            v-model="data.payment.date"
            v-maska="expirationDateMask"
            class="d"
            :placeholder="t('booking-payment.form.cardExpirationDate')"
            :disabled="loading.is">

        <input 
            v-model="data.payment.master"
            class="m"
            :placeholder="t('booking-payment.form.cardHolder')"
            :disabled="loading.is">

        <input 
            v-model="data.payment.cvc"
            v-maska="['###', '####']"
            class="c"
            :placeholder="t('booking-payment.form.cardCVC')"
            :disabled="loading.is">
    </form>

    <div class="spacer"></div>
    <div v-if="infoEvent.type">{{ infoEvent.message }}</div>
    <div class="spacer"></div>

    <div class="booking-dialog-btn-next-wrap">
        <button
            class="booking-dialog-btn booking-dialog-btn-next prev"
            :disabled="loading.is"
            @click="$emit('next', prev)"
            v-text="t('button.prev')" />

        <button
            class="booking-dialog-btn booking-dialog-btn-next next"
            :disabled="!isValid.value || loading.is"
            @click="$emit('next', next)"
            v-text="t('button.pay')"
            v-dloading="loading.is" />
    </div>
</template>

<script>
import { reactive, onBeforeMount } from 'vue'
import { useI18n } from 'vue-i18n'
import { DATA, infoEvent } from '@/App.vue'

export default {
    name: 'booking-payment',

    emits: [ 'next' ],

    props: [ 'prev', 'next', 'data', 'isValid', 'loading' ],

    setup(props) {

        const { t, locale } = useI18n()

        onBeforeMount(() => {
            props.data.payment ??= { ...DATA.payment }
        })

        return {
            t,
            locale,
            expirationDateMask: { 
				mask: 'Z#/Y#', 
				tokens: { 
					'Z': { pattern: /[0-1]/ }, 
					'Y': { pattern: /[2]/ }
				}
			},
            infoEvent
        }
    }
}
</script>