import { createApp } from 'vue'
import App from '@/App.vue'
import i18n from '@/i18n'
import Maska from 'maska'
import { init } from './App.vue'
import { loading } from '@/components/Loading'

console.info('Init Bokking App...')

createApp(App)
    .use(i18n)
    .use(Maska)
    .directive('dloading', loading)
    .mount('#__booking__')

console.info('Booking App starded!')
init()
