<template>
   <div 
        class="booking-dialog-content-title"
        v-text="t('booking.subtitle')" />

    <div class="booking-dialog-layout">

        <div class="l1">
            <div v-text="t('booking.guest_num')" />
            <div class="booking-dialog-guests">
                <button
                    v-for="(num, idx) in guests"
                    :key="idx"
                    :class="[data.guestNum == num ? 'active' : '']"
                    :disabled="!editable"
                    @click="data.guestNum = num">
                    {{ num }}
                </button>
            </div>
        </div>

        <div class="l2">

            <div v-text="t('booking.date')" />

            <div class="booking-dialog-calendar">

                <div 
                    :class="[
                        'booking-dialog-calendar-date',
                        dialogCalendar ? 'open' : null
                    ]"
                    @click="dialogCalendar = !dialogCalendar">
                        <div class="booking-dialog-calendar-date-label">{{ dateView }}</div>
                    </div>

                <div v-if="dialogCalendar"
                    class="booking-dialog-calendar-month">
                    <button 
                        class="booking-dialog-calendar-month-pre"
                        :disabled="isBeforeMonth() || !editable"
                        @click="month(-1)" />

                    <span class="booking-dialog-calendar-month-current">{{ monthDate }}</span>

                    <button 
                        class="booking-dialog-calendar-month-next"
                        :disabled="!editable"
                        @click="month(1)"  />
                </div>
                <div
                    v-if="dialogCalendar"
                    class="booking-dialog-calendar-days"> 

                    <div
                        v-for="(day, idx) in days"
                        :key="day + idx">
                        {{ day }}
                    </div>

                    <div
                        v-for="(day, idx) in preDays"
                        :key="day + idx"
                        class="booking-dialog-calendar-days-disabled">
                        {{ day }}
                    </div>

                    <button
                        v-for="(day, idx) in daysInMonthArr"
                        :key="day + idx"
                        :class="[
                            'booking-dialog-calendar-days-day', 
                            isToday(day) ? 'today' : null,
                            isDay(day) ? 'active' : null,
                            isAfter(day) ? 'after' : null,
                            dayDisabled(day) ? 'after' : null
                        ]"
                        :disabled="isAfter(day) || dayDisabled(day) || !editable"
                        @click="setDate(day)">
                        {{ day }}
                    </button>

                    <button
                        v-for="(day, idx) in afterDays"
                        :key="day + idx"
                        :disabled="!editable"
                        class="booking-dialog-calendar-days-disabled">
                        {{ day }}
                    </button>

                </div>

            </div>
        </div>

        <div class="l3">

            <div v-if="times.length && times.some(e => e.active)" v-text="t('booking.time')" />
            
            <div 
                v-if="times.length && (times.some(e => e.active) || editable)"
                class="booking-dialog-times">
                <button
                    v-for="(time, idx) in times"
                    :key="idx"
                    :class="[
                        data.time == time.time || data.time == time.time + ':00' ? 'active' : null,
                        time.active ? null : 'disabled'
                    ]"
                    :disabled="!time.active || !editable"
                    @click="data.time = time.time"
                    style="white-space: nowrap;">
                    {{ momentTime(time.time) }}
                </button>
            </div>
            
            <div 
                v-show="!times.length || times.every(e => !e.active)">
                <span v-text="t('booking.no_time.pre')" /><a @click="$emit('next', waiting)" v-text="t('booking.no_time.link')" /><span v-text="t('booking.no_time.after')" />
            </div>

            <div class="spacer"></div> 

            <template v-if="Number(data.guestNum)">

                <div v-html="t('text.total', { 
                        n: data.guestNum, 
                        t: (Number(data.guestNum) * 12000).toLocaleString('ru'),
                        date: dateTotalView,
                        t0: (Number(data.guestNum) * 8000).toLocaleString('ru'),
                        t1: (Number(data.guestNum) * paymentConf.amount).toLocaleString('ru')
                    })" />
                <!-- <div _v-text="t('text.amount')">К оплате <b>{{ dateTotalView }}</b> &mdash; {{ (Number(data.guestNum) * 7500).toLocaleString('ru') }} &#8381;</div>
                <div _v-text="t('text.amount')">К оплате сейчас &mdash; {{ (Number(data.guestNum) * paymentConf.amount).toLocaleString('ru') }} &#8381;</div> -->
                
                <div class="booking-dialog-agree">
                    <img :src="`/img/checkbox-${isAgree ? 'on' : 'off'}.svg`" @click="isAgree = !isAgree" >
                    <span v-html="t('booking.agreement')" @click.stop="isAgree = !isAgree" />
                </div>

                <!-- <div v-text="t('text.amount')" />
                <div 
                    :class="{
                        'booking-dialog-amount': true,
                        disabled: !data.time
                    }">
                    {{ (Number(data.guestNum) * paymentConf.amount).toLocaleString('ru') }}
                </div> -->
            </template>
            
        </div>

    </div>

    <div class="booking-dialog-btn-next-wrap">
        <button
            class="booking-dialog-btn booking-dialog-btn-next prev"
            @click="$emit('next', prev)"
            v-text="t('button.prev')" />

        <button
            class="booking-dialog-btn booking-dialog-btn-next next"
            :disabled="!isValid.value || !isAgree"
            @click="$emit('next', next)"
            v-text="t('button.next')" />
    </div>
    
</template>

<script>
import { ref, watch, computed, onBeforeMount } from 'vue'
import { useI18n } from 'vue-i18n'
import moment from 'moment'
import { paymentConf } from '@/App.vue'

export default {
    name: 'booking',

    emits: [ 'prev', 'next' ],

    props: [ 'data', 'next', 'prev', 'waiting', 'isValid', 'loading', 'editable' ],

    setup(props) {

        const { locale, t } = useI18n()

        moment.locale(locale.value)

        const isAgree = ref(false)
        
        const guests = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18]
        const date = ref(moment().format('YYYY-MM-DD'))
        const slots = ref([])
        const curSlots = ref([])
        const times = ref([])
        const dialogCalendar = ref(false)

        const dateView = computed(() => moment(props.data.date).format('MMMM D, YYYY'))
        const dateTotalView = computed(() => moment(props.data.date).format('D MMM YYYY'))
        const monthDate = computed(() => moment(date.value).format('MMMM YYYY'))

        const days = computed(() => {
            const days = []
			for (var i = 1; i <= 7; i++) {
				days.push(moment().day(i).format('ddd'))
			}
			return days
        })

        const preDays = computed(() => {
            const days = []
			const startMonth = moment(date.value).startOf('month')
			const pDays = startMonth.day() || 7
			for (var i = 1; i < pDays; i++) {
				days.unshift(startMonth.add(-1, 'day').format('D'))
			}
			return days
        })

        const afterDays = computed(() => {
            const days = []
			const endMonth = moment(date.value).endOf('month')
			const aDays = endMonth.day() || 7
			for (var i = 7; i > aDays; i--) {
				days.push(endMonth.add(1, 'day').format('D'))
			}
			return days
        })

        const daysInMonth = computed(() => moment(date.value).daysInMonth())

        const daysInMonthArr = computed(() => {
            const days = []
			for (var i = 1; i <= daysInMonth.value; i++) {
				days.push(i)
			}
			return days
        })

        // onBeforeMount(async () => {
            // console.log('onBeforeMount...')
            // await getSlots()
            // genTimes
        // })

        const month = div => date.value = moment(date.value).add(div, 'M').format('YYYY-MM-DD')
        const isBeforeMonth = () => moment(date.value).add(-1, "M").isBefore(moment(), 'month')
        const isToday = day => moment().isSame(moment(date.value).date(day), 'day')
        const isDay = day => moment(props.data.date).isSame(moment(date.value).date(day), 'day')
        const isAfter = day => moment().isAfter(moment(date.value).date(day), 'day')
        const isBefore = day => moment().isBefore(moment(date.value).date(day), 'day')

        const dayDisabled = day => {
            // const date = moment(date.value).date(day).format('YYYY-MM-DD')
			// const list = this.slots.filter(f => f.date == date)
			// const available = list.reduce((res, e) => res += (e.limit || 0) - (e.booked + (e.bookedOverlay || 0)), 0)
			// return !list.length || available < this.data.guestNum
            return false
        }

        const momentTime = time => moment(time, 'HH:mm').format(locale.value == 'ru' ? 'HH:mm' : 'hh:mm a')

        const genTimes = () => {
			const list = curSlots.value
				.map(m => ({ time: moment(m.time, "HH:mm:ss").format("HH:mm"), active: m.limit && m.limit - (m.booked + m.bookedOverlay) - props.data.guestNum >= 0 }))

			times.value.splice(0)
            // console.log({list})
			// if (list.some(f => f.active)) 
            times.value.push(...list)
        }

        const getSlots = async () => {
            const from = moment(moment(date.value).format(`YYYY-MM-`) + `01`)
            const to = moment(from)
            from.subtract(6, 'days')
            to.add(36, 'days')
            // console.log('props.data.date.value::', props.data.date)
            // const from = moment(props.data.date)
            // const to = from

            try {
				const response = await fetch(`/api/v1.0/booking/slots/${from.format(`YYYY-MM-DD`)}/${to.format(`YYYY-MM-DD`)}`, {
				    method: 'GET',
				    mode: 'cors',
				    cache: 'no-cache',
				    credentials: 'same-origin'
			  	})

				slots.value.splice(0)

			  	if (!response.ok) {
				    throw new Error('Ответ сети был не ok.');
			  	}

			  	const resSlots = await response.json()
			  	if (resSlots && Array.isArray(resSlots)) slots.value.push(...resSlots)
			} catch(e) {
				console.log(e)
			}
        }

        const setDate = day => {
            if (isAfter(day)) return  

			props.data.date = moment(date.value).date(day).format('YYYY-MM-DD')
        }

        // watch(slots, () => genTimes(), { deep: true })

        watch(date, async () => {
            // if (!slots.value.length) 
            // console.log('watch date...')
            await getSlots()
            genTimes()
        })

        watch(() => props.data.date, async date => {
            // if (!slots.value.length) 
            console.log('watch props.data.date...')
            await getSlots()
            curSlots.value.splice(0)
            curSlots.value.push(...slots.value.filter(f => f.date == date && f.limit > 0))
            genTimes()
        }, { immediate: true })

        watch(() => props.data.guestNum, () => {
            genTimes()           
        })

        watch(times, times => {
            console.log({times})
            if (props.data.time && times.length && times.some(s => !s.active && s.time == props.data.time)) props.data.time = null
        }, { deep: true })

        return {
            t, 
            locale,
            isAgree,
            date,
            guests,
            dialogCalendar,
            dateView,
            dateTotalView,
            monthDate,
            days,
            preDays,
            afterDays,
            daysInMonthArr,
            month,
            times,
            isBeforeMonth,
            isToday,
            isDay,
            isAfter,
            isBefore,
            dayDisabled,
            momentTime,
            setDate,
            paymentConf
        }
    }
}
</script>