<template>
    <div class="booking-loading">
        <img src="/img/loading.svg">
    </div>
</template>

<script>
const loading = {
    beforeMount(el, binding, vnode, prevVnode) {},
    mounted(el, binding, vnode, prevVnode) {
        const l = document.createElement('div')
        l.classList.add('booking-loading')
        l.style.display = binding.value ? 'unset' : 'none'
        const i = new Image()
        i.src = '/img/loading.svg'
        l.appendChild(i)
        el.appendChild(l)
    },
    beforeUpdate() {},
    updated(el, binding, vnode, prevVnode) {
        try {
            el.querySelector('.booking-loading').style.display = binding.value ? 'unset' : 'none'
        } catch (e) {
            console.log(e)
        }
    },
    beforeUnmount() {},
    unmounted() {}
}

export default {
    name: 'loading',
    setup() {}
}

export {
    loading
}
</script>
