<template>
    <div 
        class="booking-dialog-content-title"
        v-text="t('booking-details.subtitle')" />
    
    <form class="flex booking-dialog-details" @submit="() => false">
        <input 
            v-model="data.firstName"
            class="fn"
            :placeholder="t('booking-details.form.name')"
            :disabled="loading.is || !editable">

        <input 
            v-model="data.lastName"
            class="ln"
            :placeholder="t('booking-details.form.surname')"
            :disabled="loading.is || !editable">

        <input 
            v-model="data.phone"
            v-maska="'#-###-###-##-##'"
            type="tel"
            class="t"
            :placeholder="t('booking-details.form.tel')"
            :disabled="loading.is || !editable">

        <input 
            v-model="data.email"
            class="e"
            :placeholder="t('booking-details.form.email')"
            :disabled="loading.is || !editable">

        <textarea
            v-model="data.restriction"
            rows="3"				
            class="r"
            :placeholder="t('booking-details.form.restriction')" 
            :disabled="loading.is || !editable" />

    </form>
    
    <div class="spacer"></div>

    <div class="booking-dialog-btn-next-wrap">
        <button
            class="booking-dialog-btn booking-dialog-btn-next prev"
            :disabled="loading.is"
            @click="$emit('next', prev)"
            v-text="t('button.prev')" />

        <button
            class="booking-dialog-btn booking-dialog-btn-next next"
            :disabled="!isValid.value || loading.is"
            @click="$emit('next', next)"
            v-text="t('button.pay')"
            v-dloading="loading.is" />
    </div>
</template>

<script>
import { useI18n } from 'vue-i18n'

export default {
    name: 'booking-details',

    emits: [ 'next' ],

    props: [ 'prev', 'next', 'data', 'isValid', 'loading', 'editable' ],

    setup() {

        const { t, locale } = useI18n()

        return {
            t,
            locale
        }
    }
}
</script>