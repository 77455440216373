<template>


    <div class="spacer"></div>

    <span class="booking-dialog-payment-check">
        <span v-dloading="true" class="booking-dialog-payment-check-spinner" />
        <span v-html="t('booking-payment-check-status.check')" />
    </span>

    <div class="spacer"></div>

    <!-- <div class="booking-dialog-btn-next-wrap">
        <button
            class="booking-dialog-btn booking-dialog-btn-next ok"
            @click="$emit('next', next)">
            OK
        </button>
    </div> -->
			
</template>

<script>
import { onMounted } from 'vue'
import { useI18n } from 'vue-i18n'
import { payStatus, paymentData } from '@/App.vue'

export default {
    name: 'booking-payment-check-status',

    props: [ 'data', 'next', 'prev', 'isValid', 'loading' ],

    emits: [ 'next' ],

    setup(props) {
        const { t, locale } = useI18n()

        const reCheck = () => {
            setTimeout(async () => {
                await payStatus()
            }, 3000)
        }

        onMounted(() => {
            console.log('booking-payment-check-status onMounted...')
            reCheck()
        })

        return {
            t,
            locale
        }
    }
}
</script>
