<template>
    <div 
        class="booking-wrapper"
        @click.self="$emit('close')">
        <div class="booking-dialog dark">
            <div class="booking-dialog-header">
                <h1><slot name="header" /></h1>
                <span class="spacer"></span>
                <slot name="info" />
            </div>
            <div class="booking-dialog-content">
                <slot name="content" />            
            </div> 
        </div>
    </div>
</template>

<script>

export default {
    name: 'booking-dialog',

    emits: [ 'close' ],

    setup() {
        
    }
}
</script>