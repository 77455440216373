<template>
    <div class="booking-dialog-welcome">

        <div v-html="$t('welcome.subtitle')" />

        <div class="booking-dialog-agree">
            <img :src="`/img/checkbox-${isAgree ? 'on' : 'off'}.svg`" @click="isAgree = !isAgree" >
            <span v-html="$t('welcome.booking')" @click.stop="isAgree = !isAgree" />
        </div>

        <div class="spacer"></div>

    </div>

    <div class="spacer"></div>

    <div class="booking-dialog-btn-next-wrap"> 
        <button
            class="booking-dialog-btn booking-dialog-btn-next ok"
            :disabled="!isAgree"
            @click="$emit('next', booking)">
            {{ $t('button.booking') }}
        </button>
    </div>
    
</template>

<script>
import { ref } from 'vue'
import { useI18n } from 'vue-i18n'

export default {
    name: 'welcome',

    emits: [ 'next' ],

    props: [ 'booking', 'waiting', 'isValid' ],

    setup() {
        const isAgree = ref(false)
        return { isAgree }
    }

}
</script>