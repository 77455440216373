<template>
    <div 
        class="booking-dialog-content-title"
        v-text="t('waiting.subtitle')" />
    
    <form class="flex booking-dialog-details" @submit="() => false">
        <input 
            v-model="data.firstName"
            class="fn"
            :placeholder="t('waiting.form.name')">

        <input 
            v-model="data.lastName"
            class="ln"
            :placeholder="t('waiting.form.surname')">

        <input 
            v-model="data.phone"
            v-maska="'#-###-###-##-##'"
            type="tel"
            class="t"
            :placeholder="t('waiting.form.tel')" />

        <input 
            v-model="data.email"
            class="e"
            :placeholder="t('waiting.form.email')">

        <textarea
            v-model="data.restriction"
            rows="3"				
            class="r"
            :placeholder="t('waiting.form.restriction')" />

    </form>

    <div class="spacer"></div>

    <div class="l1">
        <div v-text="t('waiting.form.guest_num')"></div>
        <div class="booking-dialog-guests">
            <button
                v-for="(num, idx) in guests"
                :key="idx"
                :class="[data.guestNum == num ? 'active' : '']"
                @click="data.guestNum = num">
                {{ num }}
            </button>
        </div>
    </div>

    <div 
        style="padding-top: 20px;"
        v-text="t('waiting.message')" />


    <div class="spacer"></div>

    <div class="booking-dialog-btn-next-wrap">
        <button
            class="booking-dialog-btn booking-dialog-btn-next prev"
            @click="$emit('next', prev)"
            v-text="t('button.prev')" />

        <button
            class="booking-dialog-btn booking-dialog-btn-next next"
            :disabled="!isValid.value"
            @click="$emit('next', next)"
            v-text="t('button.next')" />
    </div>
</template>

<script>
import { useI18n } from 'vue-i18n'

export default {
    name: 'waiting',

    emits: [ 'prev', 'next' ],

    props: [ 'prev', 'next', 'data', 'isValid', 'loading' ],

    setup() {

        const { t, locale } = useI18n()
        const guests = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]

        return { 
            t, 
            locale,
            guests
        }    
    }
}
</script>
